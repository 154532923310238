<template>
  <a
    :class="[className, { 'themed-button': theme }]"
    href="#"
    class="btn btn-has-state"
    :disabled="disabled ? 'disabled' : null"
    :style="buttonTheme"
    @click.exact.prevent.stop="handleClick"
    @click.alt.prevent="$emit('alt-click')">
    <span class="btn-text-node flex justify-center items-center">
      <slot></slot>
    </span>
    <fade-transition>
      <span
        v-if="state === 'loading'"
        class="btn-state btn-loading-state">
        <div class="loading-dot loading-dot-1"></div>
        <div class="loading-dot loading-dot-2"></div>
        <div class="loading-dot loading-dot-3"></div>
      </span>
    </fade-transition>
    <fade-transition>
      <span
        v-if="state === 'error'"
        class="btn-state btn-success-state">
        <svgIcon name="cross" />
      </span>
    </fade-transition>
    <fade-transition>
      <span
        v-if="state === 'success'"
        class="btn-state btn-success-state">
        <svgIcon name="tick" />
      </span>
    </fade-transition>
  </a>
</template>

<script>
import FadeTransition from "../transitions/FadeTransition.vue";
import SvgIcon from "./SvgIcon.vue";

export default {
  name: "Btn",
  components: {
    FadeTransition,
    SvgIcon
  },
  props: {
    type: {
      type: String,
      default: "primary"
    },
    promise: {
      type: Function,
      default: null
    },
    theme: {
      type: Object,
      default: () => {}
    },
    disabled: {
      type: Boolean,
      default: null
    }
  },
  emits: ["click", "alt-click", "promise-fulfilled"],
  data() {
    return {
      state: ""
    };
  },
  computed: {
    className() {
      return {
        [`btn-${this.type}`]: !this.theme,
        "btn-loading": this.state === "loading",
        "btn-loading-error": this.state === "error",
        "btn-loading-success": this.state === "success"
      };
    },
    buttonTheme() {
      if (this.theme) {
        return {
          borderRadius: this.theme.button.borderRadius,
          textTransform: this.theme.button.textTransform,
          fontFamily: this.theme.button.fontFamily,
          outline: this.theme.button.outline,
          letterSpacing: this.theme.button.letterSpacing,
          "--button-color": this.theme.button.color,
          "--button-background-color": this.theme.button.backgroundColor,
          "--button-border-color": this.theme.button.backgroundColor,
          "--button-box-shadow": this.theme.button.boxShadow,
          "--button-hover-color": this.theme.button.hover.color,
          "--button-hover-background-color":
            this.theme.button.hover.backgroundColor,
          "--button-hover-border-color": this.theme.button.hover.borderColor,
          "--button-hover-box-shadow": this.theme.button.hover.boxShadow,
          "--button-focus-color": this.theme.button.focus.color,
          "--button-focus-background-color":
            this.theme.button.focus.backgroundColor,
          "--button-focus-border-color": this.theme.button.focus.borderColor,
          "--button-focus-box-shadow": this.theme.button.focus.boxShadow
        };
      }
      return null;
    }
  },
  methods: {
    showLoader() {
      this.state = "loading";
    },
    hideLoader() {
      this.state = "";
    },
    handleClick() {
      if (this.state === "loading" || this.state === "success") {
        // stop double click issues
        // if button has any state then that means it has just been clicked
        // state resets to null two seconds after promise executes
        // this is when we can click the button again
      } else if (this.promise) {
        this.callPromise();
      } else {
        this.$emit("click");
      }
    },
    callPromise() {
      const vm = this;
      this.state = "loading";
      this.promise
        .call()
        .then(() => {
          vm.state = "success";
          setTimeout(() => {
            vm.state = null;
            vm.$emit("promise-fulfilled");
          }, 2000);
        })
        .catch(() => {
          vm.state = "error";
          setTimeout(() => {
            vm.state = null;
          }, 2000);
        });
    }
  }
};
</script>

<style lang="scss">
.btn-text-node {
  visibility: visible;
  transition: opacity 0.2s linear;
}

.btn-has-state {
  position: relative;

  .icon-tick,
  .icon-cross {
    position: absolute;
    top: 50%;
    left: 50%;
  }

  .icon-tick {
    margin-top: -5px;
    margin-left: -4px;
  }

  .icon-cross {
    margin-top: -5px;
    margin-left: -5px;
  }
}

.btn-loading,
.btn-loading-success,
.btn-loading-error {
  .btn-text-node {
    opacity: 0;
  }
}

.btn-loading-state {
  .loading-dot {
    height: 4px;
    width: 4px;
    border-radius: 50%;
    background-color: currentColor;
    position: absolute;
    top: 50%;
    margin-top: 5px;
    left: 50%;
    animation: loading-bounce 0.45s cubic-bezier(0, 0, 0.15, 1) alternate
      infinite;
  }

  .loading-dot-1 {
    margin-left: -10px;
  }

  .loading-dot-2 {
    margin-left: -2px;
    animation-delay: 0.15s;
  }

  .loading-dot-3 {
    margin-left: 6px;
    animation-delay: 0.3s;
  }
}

.btn-primary {
  .btn-loading-state .loading-dot {
    background-color: #fff;
  }
}

@keyframes loading-bounce {
  0% {
    transform: translateY(0) scaleY(0.8);
  }

  100% {
    transform: translateY(-10px);
  }
}

.themed-button {
  // Set some defaults, these can be overridden in the buttonTheme
  --button-color: #343434;
  --button-background-color: #efefef;
  --button-border-color: #efefef;
  --button-hover-color: #343434;
  --button-hover-background-color: #121212;
  --button-hover-border-color: #121212;
  --button-font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    Arial, sans-serif;

  // We don't want to change some things in a theme
  border-radius: 3px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  font-weight: unset;
  transition: background 0.1s ease;
  font-family: var(--button-font-family);
  color: var(--button-color);
  background-color: var(--button-background-color);
  border-color: var(--button-border-color);
  box-shadow: var(--button-box-shadow);

  // Hover/focus styles must use css variables
  &:hover {
    color: var(--button-hover-color);
    background-color: var(--button-hover-background-color);
    border-color: var(--button-hover-border-color);
    box-shadow: var(--button-hover-box-shadow);
  }

  &:focus {
    color: var(--button-focus-color);
    background-color: var(--button-focus-background-color);
    border-color: var(--button-focus-border-color);
    box-shadow: var(--button-focus-box-shadow);
  }
}
</style>
